

















import { vxm } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GenericGridCard extends Vue {
  @Prop(String) readonly route: string | undefined;
  @Prop(String) readonly title: string | undefined;
  @Prop(String) readonly description: string | undefined;
  @Prop({default: 'icon-info-with-shadow.svg'}) readonly image!: string;

  private ui = vxm.ui;
  private publicPath = process.env.VUE_APP_STATIC_DIR;

  private navigate() {        
    if (!this.ui.dragging) {
      this.$router.push(this.route!);
    }
  }  
}

  (function () {
  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  console.log( process.env );
  
  })();

