

















import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';


@Component
export default class OkModal2 extends Vue {
    @Prop(String) readonly title: string | undefined;
    @Prop(String) readonly message: string | undefined;
    @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
    @PropSync('dismissed', { type: Boolean }) syncedDismiss!: boolean;


    private dismiss(){ //instead of using events which are a little opaque, I intend to use a watch on these variables
       this.syncedShow=false;
       this.syncedDismiss=true;
    }

}
